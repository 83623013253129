let resumeData = {
    "imagebaseurl":"https://pollutionprevents.com",
    "name": "Pollution Preventers",
    "role": "",
    "linkedinId":"Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "Pollution Preventers is an organization created as a Girl Scout Gold Award Project in an effort to reduce the worlds pollution by educating and informing people about the causes and dangers of pollution!",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":"",
          "className":"fa fa-twitter"
        }
      ],
    "aboutme":"I was motivated into action by my passion for marine life and their habitat. Ocean pollution has been a reoccurring issue for quite sometime now as it shakes the foundations of the ocean’s ecosystems. Thus, my project was targeted at raising an awareness to keep our oceans clean for all future generations. It’s our world, love it!",
    "address":"Orange County, California",
    "website":"",
    "education":[
      {
        "UniversityName":"The LNM Insitute of Information Technology",
        "specialization":"Some specialization",
        "MonthOfPassing":"Aug",
        "YearOfPassing":"2020",
        "Achievements":"Some Achievements"
      },
      {
        "UniversityName":"Some University",
        "specialization":"Some specialization",
        "MonthOfPassing":"Jan",
        "YearOfPassing":"2018",
        "Achievements":"Some Achievements"
      }
    ],
    "work":[
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      },
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Reactjs"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData
